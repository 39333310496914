import logo from './logo.svg';
import './App.css';
import {Home} from "./Home";
import {About} from "./About";
import {Services} from "./Services";
import {InstallationA} from "./Installation_A";
import {ContactMiniBanner} from "./Contact_Mini_Banner";
import {Contact} from "./Contact";

function App() {
  return (
    <div className="App container-fluid">
      <Home/>
      <About/>
      <Services/>
      <ContactMiniBanner/>
      <InstallationA/>
      <Contact/>
    </div>
  );
}

export default App;
