import * as React from 'react';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import PLACEHOLDER from './assets/electrician-working-with-cable-on-the-construction-site-.jpg';
import INSTAGRAM from './assets/instagram.svg';
import {useEffect, useState} from "react";

// Solar images
import solar_1 from './assets/Solar/13-3bd73e57-024a-4cf9-a7f8-09ac008ed73a.jpg';
import solar_2 from './assets/Solar/12-35b18a26-2a25-4240-afb8-4dc576236ed3.jpg';
import solar_3 from './assets/Solar/16-38fdd053-1b0f-4fa0-b579-8d4291be6783.jpg';
import solar_4 from './assets/Solar/41-10ee6753-809d-4142-a248-01b2ca180c66.jpg';
import solar_5 from './assets/Solar/59-7604079d-d152-46ac-a08e-275809bab831.jpg';
import solar_6 from './assets/Solar/60-7923621b-9d8f-4a12-b531-fe7a6f0576e6.jpg';
import solar_7 from './assets/Solar/64-20a98a01-4aff-4ed9-b865-3eb32ece9bc2.jpg';
import solar_8 from './assets/Solar/137-0e58ca78-68e1-4364-b4f7-1d4213c0d0ff.jpg';

// Main electrical boxes
import ebox_1 from './assets/Main electrical box upgrades/21-8a071c63-4513-4149-9086-a71069711144.jpg';
import ebox_2 from './assets/Main electrical box upgrades/8-84a4a389-4bc0-477b-87a5-732ed6dcfdfa.jpg';
import ebox_3 from './assets/Main electrical box upgrades/11-d3ae9157-7b95-4555-8c8b-fd0fd085175e.jpg';
import ebox_4 from './assets/Main electrical box upgrades/13-7eb4f692-b823-44a6-a5ef-df5cb5faaa9e.jpg';
import ebox_5 from './assets/Main electrical box upgrades/14-db89dbdb-74fc-4e09-adb6-96041d3355c8.jpg';
import ebox_6 from './assets/Main electrical box upgrades/32-8e8fcb66-72e5-4f0a-b77d-5c056b608a6e.jpg';
import ebox_7 from './assets/Main electrical box upgrades/42-8f9a5312-471d-4f18-9122-0c65575a2621.jpg';
import ebox_8 from './assets/Main electrical box upgrades/45-80ea71ac-2b71-468a-8845-d8851682616e.jpg';
import ebox_9 from './assets/Main electrical box upgrades/52-28f19462-e498-491f-99be-4972fc21e4d8.jpg';
import ebox_10 from './assets/Main electrical box upgrades/53-800c32c8-d00e-474e-9b01-88cb11a22100.jpg';

// Batteries
import battery_1 from './assets/Batteries/0-2d29c9af-dbfd-4f14-a2f1-331911069282.jpg';
import battery_2 from './assets/Batteries/18-bd456904-f336-4aa4-a9c6-5d114bc68a8e.jpg';
import battery_3 from './assets/Batteries/10-17ad93ec-4f5f-4252-af39-f6f53da897a1.jpg';

export const InstallationA = () => {
    const [carouselIndex, setCarouselIndex] = useState(0);

    useEffect(() => {
        let myCarousel = document.getElementById('carouselExampleCaptions');
        myCarousel.addEventListener('slide.bs.carousel', (e) => {
            // Set index to update the grid below the carousel
            setCarouselIndex(e.to);
        });
    }, []);

    return (
        <div id="installation" className="container margin-spacing-t-1 margin-spacing-b-1">
            <div className="row">
                <div className="col-12">
                    <p className="font-weight-bold">
                        <div className="electric-icon-svg-alt">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                                <path
                                    d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                            </svg>
                        </div>
                        Installations
                    </p>
                    <h1 className="heading-font-1">Have a look at our previous<br/> <span
                        className="text-highlight">installations</span></h1>

                    <p className="mt-5 text-body line-spacing-1">Want to see more? Check out Instagram down below</p>
                    <a href="https://www.instagram.com/365_electricinc/">
                        <button className="btn btn-primary button-spacing-right">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white"
                                 className="bi bi-instagram" viewBox="0 0 16 16">
                                <path
                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                            </svg>
                        </button>
                    </a>

                <div className="margin-spacing-t-2">
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img src={solar_1} className="d-block w-100"
                                         style={{objectFit: 'cover', objectPosition: 'center'}} height={450} alt="..."/>
                                    <div className="carousel-caption d-md-block">
                                        <h5>Solar installations</h5>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={ebox_1} className="d-block w-100"
                                         style={{objectFit: 'cover', objectPosition: 'center'}} height={450} alt="..."/>
                                    <div className="carousel-caption d-md-block">
                                        <h5>Main electrical box upgrades</h5>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img src={battery_1} className="d-block w-100"
                                         style={{objectFit: 'cover', objectPosition: 'center'}} height={450} alt="..."/>
                                    <div className="carousel-caption d-md-block">
                                        <h5>Batteries</h5>
                                    </div>
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>


                </div>

                <div className="col-12 mt-5">
                    {/*Solar*/}
                    { carouselIndex === 0 && <div className="row">
                        <div className="col-3 ">
                            <img src={solar_1} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_2} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_3} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_4} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_5} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_6} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_7} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={solar_8} className="img-fluid mt-3" alt=""/>
                        </div>
                    </div>}
                    {/*Main electrical*/}
                    {carouselIndex === 1 && <div className="row">
                        <div className="col-3 ">
                            <img src={ebox_1} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_2} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_3} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_4} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_5} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_6} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_7} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_8} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_9} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={ebox_10} className="img-fluid mt-3" alt=""/>
                        </div>
                    </div>}
                    {/*Batteries*/}
                    {carouselIndex === 2 && <div className="row">
                        <div className="col-3 ">
                            <img src={battery_1} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={battery_2} className="img-fluid mt-3" alt=""/>
                        </div>
                        <div className="col-3 ">
                            <img src={battery_3} className="img-fluid mt-3" alt=""/>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};