import * as React from 'react';
import {useState} from "react";
import BRAND from './assets/365_2024-07-29_22-37.png';
// import PLACEHOLDER from './assets/pexels-pavel-danilyuk-6072916.jpg';
// https://365electric.samexplains.com

export const Home = () => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <div className="row">
            {/*Navigation*/}
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={BRAND} className="img-fluid" width={100} alt=""/>
                    </a>
                    <button className="navbar-toggler my-auto" type="button" aria-label="Toggle navigation" onClick={handleNavCollapse}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"
                             className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                            <path
                                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg>
                    </button>
                    {/* toggle the show here*/}
                    <div className={!isNavCollapsed ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'}
                         id="navbarSupportedContent">
                        {/*<div className="collapse navbar-collapse show" id="navbarSupportedContent">*/}
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#services">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#installation">Installations</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/*<nav className="navbar navbar-light bg-light">*/}
            {/*    <div className="container-fluid">*/}
            {/*        <a className="navbar-brand" href="#">*/}
            {/*            <img src="/docs/5.0/assets/brand/bootstrap-logo.svg" alt="" width="30" height="24"*/}
            {/*                 className="d-inline-block align-text-top"/>*/}
            {/*            Bootstrap*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*</nav>*/}

            <div className="col-12 gradient-pattern position-relative">
                <div className="container">
                    <div className="row">
                        {/* Home */}
                        {/* margin-spacing-t-1 */}
                        <div className="home-container col-12 mt-5">
                            <div className="row mt-5">
                                <div className="col-12 text-center margin-spacing-t-2">
                                    {/*<p className="font-weight-bold">*/}
                                    {/*    <div className="electric-icon-svg">*/}
                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"*/}
                                    {/*             fill="currentColor"*/}
                                    {/*             className="bi bi-lightning-charge-fill" viewBox="0 0 16 16">*/}
                                    {/*            <path*/}
                                    {/*                d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </div>*/}
                                    {/*    365 Electric Inc*/}
                                    {/*</p>*/}
                                    <h1 className="heading-font-1 font-color-w65 mt-4 mb-5">Power your <br/> next
                                        project <br/> with us
                                    </h1>
                                    <a href="#services">
                                        <button className="btn btn-primary mr-3">Our Services</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};