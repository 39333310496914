import * as React from 'react';
import PLACEHOLDER from './assets/electrician-working-with-cable-on-the-construction-site-.jpg';
export const ContactMiniBanner = () => {
    return (
        <div className="container margin-spacing-t-1 margin-spacing-b-1">
            <div className="row">
                <div className="col-12 contact-banner-container">
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-3">
                            <img src={PLACEHOLDER} className="img-fluid contact-banner-img" alt=""/>
                        </div>
                        <div className="col-12 col-md-9 col-lg-9 p-3 my-auto">
                            <div className="row">
                                <div className="col-12 col-md-8 col-lg-8">
                                    <h1 className="font-weight-bolder">In need of an Electrician?</h1>
                                    <p className="">365 Electric Inc is here to help.</p>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 my-auto">
                                    <a href="#contact">
                                        <button className="ml-5 btn btn-primary-alt" id="mini-banner">Contact Us
                                    </button>
                                    </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};