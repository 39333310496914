import * as React from 'react';
import PLACEHOLDER from './assets/Main electrical box upgrades/21-8a071c63-4513-4149-9086-a71069711144.jpg';
export const About = () => {
    return (
        <div id="about" className="container margin-spacing-t-1 margin-spacing-b-1">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-9">
                    <p className="font-weight-bold">
                        <div className="electric-icon-svg-alt">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                                <path
                                    d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                            </svg>
                        </div>
                        About Us
                    </p>
                    <h1 className="heading-font-1">We are<br/> <span className="text-highlight">specialists</span></h1>
                    <p className="mt-5 text-body line-spacing-1">365 Electric Inc is your trusted partner for all your electrical needs. Serving residential clients, we specialize in everything from simple repairs to complex installations. Our team is committed to delivering safe, efficient, and reliable service, ensuring your home runs smoothly. Whether it's upgrading your wiring, installing energy-efficient lighting, or troubleshooting electrical issues, 365 Electric Inc is here to illuminate your space with expertise and excellence.</p>
                    <a href="#installation">
                        <button className="btn btn-primary mr-3 mt-3">Our Installations</button>
                    </a>

                </div>
                <div className="col-12 col-md-12 col-lg-3 mt-5 mt-md-0 mt-md-5 my-auto">
                    <img src={PLACEHOLDER} className="img-fluid" alt=""/>
                </div>
            </div>
        </div>
    );
};