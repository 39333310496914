import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import PLACEHOLDER from "./assets/form_01-noblink.png";

export const Contact = () => {
    // name phone email message
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => emailjs.init("zTADwLCcElcPVHdm5"), []);

    const onSendEmail = async () => {
        console.log('onSendEmail');
        // Check for Name, Email and Message
         if (name.length > 0 && email.length > 0 && message.length > 0 && phone.length > 0) {
         //     confirm
             // console.log(name, phone, email, message);
             // Show success message
             await emailjs.send("service_m5s5itd", "template_mtbvgkn", {
                 to_name: '365 Electric Inc',
                 message: message,
                 from_email: email,
                 name,
                 email,
                 phone,
                 from_name: `${name} phone [${phone}] email [ ${email} ]`
             });
             document.getElementById('msg-success').classList.remove('d-none');
             // Clear the inputs
             setName("");
             setPhone("");
             setEmail("");
             setMessage("");
             setTimeout(() => {
                 document.getElementById('msg-success').classList.add('d-none');
             }, 5000)
             // alert("email successfully sent check inbox");
         } else {
             // msg-error
             document.getElementById('msg-error').classList.remove('d-none');
             setTimeout(() => {
                 document.getElementById('msg-error').classList.add('d-none');
             }, 3500)
         }
    }

    return (
        <div id="contact" className="container-fluid contact-container margin-spacing-t-1 position-relative">
            <div className="row">
                <div id="contact-lightbulbbui" className="col-12 margin-spacing-t-2">
                    <img src={PLACEHOLDER} className="img-fluid" alt=""/>
                </div>
                <div className="container position-absolute p-5">
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4">
                            <h1 className="heading-font-1">Wired for Excellence, Powered for Success!</h1>
                        </div>
                        <div className="col-12 col-md-2 col-lg-2"></div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div id="msg-success" className="bg-black text-white d-none p-3 mb-3">Message was sent!
                            </div>
                            <div id="msg-error" className="bg-black text-white d-none p-3 mb-3">Please include a Name, Email, Phone and Message
                            </div>
                            <h2>Get in touch</h2>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="John Doe"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
                                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)}
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="0000000000"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                       className="form-control" id="exampleFormControlInput1"
                                       placeholder="name@example.com"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                                <input className="form-control" value={message}
                                       onChange={(e) => setMessage(e.target.value)} id="exampleFormControlTextarea1"
                                       placeholder="message..." rows="3"></input>
                            </div>
                            <button className="btn btn-primary mb-3" onClick={() => onSendEmail()}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};