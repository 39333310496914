import * as React from 'react';
import PLACEHOLDER from "./assets/pexels-pavel-danilyuk-6072916.jpg";
import ICON1 from './assets/icon-05.gif';
import ICON2 from './assets/icon-01.gif';
import ICON3 from './assets/icon-04.gif';
import ICON4 from './assets/icon-03.gif';
import ICON5 from './assets/icon-06.gif';
import ICON6 from './assets/icon-02.gif';

export const Services = () => {
    return (
        <div id="services" className="container margin-spacing-t-1 margin-spacing-b-1">
            <div className="row">
                <div className="col-12 col-md-9 col-lg-9">
                    <p className="font-weight-bold">
                        <div className="electric-icon-svg-alt">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                                <path
                                    d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
                            </svg>
                        </div>
                        Our Services
                    </p>
                    <h1 className="heading-font-1">Experienced electricians<br/> <span
                        className="text-highlight">services</span></h1>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    <p className="text-body line-spacing-1 mt-sm-4">
                    365 Electric Inc offers a wide range of services tailored to meet residential needs. From routine electrical repairs and lighting installations to comprehensive rewiring, panel upgrades, and solar installations, our team ensures safety, efficiency, and compliance with all electrical codes.
                    </p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON1} width={75} className="img-fluid" alt=""/></div>
                    <h5 className="font-weight-bold mt-3">Circuit Installation, Upgrades & Replacements</h5>
                    <p className="text-body line-spacing-1">A crucial part of any home, installations, upgrades and replacements are a great upgrade.</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON2} width={75} alt=""/></div>
                    <h5 className="font-weight-bold mt-3">Solar Panel</h5>
                    <p className="text-body line-spacing-1 mt-adjusted">A eco friendly and popular way to generate electricity from the sun.</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON3} width={75} alt=""/></div>
                    <h5 className="font-weight-bold mt-3">Electrician & Electrical Installation <br/>Services</h5>
                    <p className="text-body line-spacing-1">Whether you are building a new home from scratch, adding to your existing home, or simply want to add electrical capabilities to your home, we can help.</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON4} width={75} alt=""/></div>
                    <h5 className="font-weight-bold mt-3">Lighting Installation</h5>
                    <p className="text-body line-spacing-1 mt-adjusted">Installed, maintained and repaired lightning can really brighten up your home.</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON5} width={75} alt=""/></div>
                    <h5 className="font-weight-bold mt-3">New Construction</h5>
                    <p className="text-body line-spacing-1 mt-adjusted">We strive to meet your new construction electrical needs quickly and efficiently</p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 margin-spacing-t-2">
                    <div><img src={ICON6} width={75} alt=""/></div>
                    <h5 className="font-weight-bold mt-3">Service & Panel Installation, Upgrades & Replacement Service</h5>
                    <p className="text-body line-spacing-1">Replacing, servicing and updating your homes panels are a great way to prevent damages.</p>
                </div>
            </div>
        </div>
    );
};